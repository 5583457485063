<template>
	<div>
		<div class="main">
			<!-- <h4>プロジェクト情報</h4> -->
			<i style="cursor:pointer" @click="goBack" class="iconfont icon-return"></i>
			<div class="formData">
				<h4 v-if="ismody">
					{{title}}
				</h4>
				<!-- <h4 v-else>第{{num}}話</h4> -->
				<el-form label-position="right" label-width="111px" :model="creatCase" :rules="caseRules">
					<el-form-item label="話のタイトル" prop="name">
                        <el-input size="small" placeholder="話のタイトルを入力して下さい" v-model="creatCase.name" style="width:35%"></el-input>
                    </el-form-item>
					<!-- 图标 -->
					<el-form-item  label="ヘッダー画像">
						<el-upload
						class="avatar-uploader"
						action="no"
						accept=".jpg, .jpeg, .png"
						:http-request="upLoadIcon"
						:limit="1"
						:show-file-list="true">
							<img v-if="creatCase.imageUrl" :src="creatCase.imageUrl">
							<i v-else class="el-icon-camera avatar-uploader-icon"></i>
						</el-upload>
					</el-form-item>
					<!-- <el-form-item label="頁数" prop="page">
                        <el-input size="small" placeholder="頁数を入力して下さい" v-model="creatCase.page" style="width:35%"></el-input>
                    </el-form-item> -->
					<el-form-item label="ネーム締め日" prop="date1">
						<el-date-picker
						v-model="creatCase.date1"
						:picker-options="pickerOptionsStart"
						size="small"
						type="date"
						default-value
						format="yyyy - MM - dd"
						value-format="yyyy-MM-dd"
						placeholder="日付を選択して下さい">
						</el-date-picker>
                    </el-form-item>
					<el-form-item label="校了締切日" prop="date2">
						<el-date-picker
						v-model="creatCase.date2"
						size="small"
						type="date"
						value-format="yyyy-MM-dd"
						format="yyyy - MM - dd"
						:picker-options="pickerOptionsEnd"
						placeholder="日付を選択して下さい">
						</el-date-picker>
                    </el-form-item>
					<el-form-item label="案件メンバー引継ぎ" prop="page" v-if="false">
						<el-radio-group v-model="creatCase.isInherit">
							<el-radio label="1">引継ぐ</el-radio>
							<el-radio label="2">引き継がない</el-radio>
						</el-radio-group>
						<el-select  size="medium" v-model="creatCase.chapterVal" placeholder="選択して下さい" style="width:25%;margin-left:30px">
                            <el-option
                            v-for="item in options"
                            :key="item.id"
                            :label="item.title"
                            :value="item.id">
                            </el-option>
                        </el-select>
					</el-form-item>
				</el-form>
			</div>
			<div class="submit">
              <span @click="checkboxP">確認画面</span>
          </div>
		</div>
		<el-dialog title="案件情報" :visible.sync="dialogFormVisible">
			<div>
				<el-descriptions title="" :column="1" :label-style="LS" border>
					<el-descriptions-item label="ヘッダー画像" v-if="creatCase.imageUrl">  <img style="max-height: 200px;" :src="creatCase.imageUrl"> </el-descriptions-item>
					<el-descriptions-item label="話のタイトル"> {{ creatCase.name }} </el-descriptions-item>
					<el-descriptions-item label="ネーム締切日"> {{ creatCase.date1 }} </el-descriptions-item>
					<el-descriptions-item label="校了締切日">{{ creatCase.date2 }}</el-descriptions-item>
					<!-- <el-descriptions-item label="案件メンバー引継ぎ" v-if="num > 1">
						<span v-if="creatCase.isInherit == 2">引き継がない</span>
						<span v-if="creatCase.isInherit == 1">{{ title }}</span>
					</el-descriptions-item> -->
				</el-descriptions>
			</div>
			 <div slot="footer" class="dialog-footer">
                <el-button @click="dialogFormVisible = false">取　消</el-button>
                <el-button type="primary" @click="confirm">確　定</el-button>
            </div>
		</el-dialog>
	</div>
</template>

<script>
import service from '@/service'
export default {
    name:'creatCase',
	data() {
		return {
			 expierTimeOption: {
				disabledDate(time) {
					return time.getTime()<Date.now()
				}
			},
			pickerOptionsStart: {
				disabledDate: time => {
					let endDateVal = this.creatCase.date2;
					if (endDateVal) {
						return time.getTime() > new Date(endDateVal).getTime();
					} else {
						return time.getTime() < Date.now() - 8.64e7;
					}
				}
			},
			pickerOptionsEnd: {
				disabledDate: time => {
					let beginDateVal = this.creatCase.date1;
					if (beginDateVal) {
						return (time.getTime() < new Date(beginDateVal).getTime()
						)
					} else {
						return time.getTime() < Date.now() - 8.64e7;
					}
				},
			},
			num: Number(this.$route.query.num) + 1,
			creatCase: {
				name:'',
				imageUrl: '',
				page: 8,
				date1: '',
				date2: '',
				isInherit: '2',
				chapterVal: 0,
			},
			title: '',
			dialogFormVisible: false,
			iconName:'',
			options:[
				
			],
			ismody: false,
			title: '',
			caseRules: {},
			id: '',
			projectid: '',
			LS: {
				'color': '#000',
				'font-weight':'700',
				'text-align': 'right',
				'width': '180px',
				'word-break': 'keep-all'
			},
		}
	},
	mounted() {
		if(this.$route.query.params) {
			this.ismody = true;
			let data = JSON.parse(this.$route.query.params);
			this.title= data.title;
			this.creatCase.name = data.name
			this.creatCase.page = data.page
			this.creatCase.date1 = data.script_at
			this.creatCase.date2 = data.check_at
			this.id = data.id,
			this.iconName = data.icon.substr(data.icon.lastIndexOf("/"))
			this.projectid = data.projectid
		} else {
			this.projectid = this.$route.query.pid;
		}
		this.getchpTitle()
	},
	watch: {
		'creatCase.chapterVal': {
			handler(val) {
				if(this.options.length) {
					this.options.forEach(item => {
						if(item.id == val){
							this.title = item.title
						}
					})
				}

			},
			deep: true,
			immediate: true
		},
		'form.date1'() {
			if (this.form.date1 == null) {
				this.form.date2 = null
			}
		}
	},
	methods: {
		getchpTitle() {
			service.personal.getchpTitle({projectid:this.projectid}).then(res => {
				if(res.status === 200) {
					this.options = res.data
					if(this.options.length) {
						this.creatCase.chapterVal = this.options[0].title
					}
					
				}
			})
		},
		goBack() {
			this.$router.go(-1);
		},
		upLoadIcon(e) {
			if(e.file) {
				const file = e.file;
				let formData = new FormData();
				formData.append("file",file);
				service.personal.uploadCapterPic(formData).then(res => {
					this.iconName = res.data.filename;
					this.creatCase.imageUrl = `/userupload/chapter/${ res.data.filename }`
					this.$message.success("アイコンがアップロードされました")
				}).catch(()=>{
					this.$message.error("アイコンのアップロードに失敗しました")
				})
			}
		},
		checkboxP() {
			if(!this.creatCase.name || !this.creatCase.date1 || !this.creatCase.date2) {
				this.$message.error('请输入必填项')
				return
			}
			this.dialogFormVisible = true
		},
		confirm() {
			let params = {
				title: `第${this.num}話`,
				name: this.creatCase.name,
				icon: this.iconName,
				projectid: this.projectid,
				script_at: this.creatCase.date1,
				check_at: this.creatCase.date2,
				inherit: 0,
				page: this.creatCase.page,
				chapterid: this.id
			}
			// if(this.creatCase.isInherit == 1) {
			// 	params.inheritchapterid = this.creatCase.chapterVal
			// }
			this.creatCase_(params)
		},
		creatCase_(data) {
			service.personal.creatCase(data).then(res => {
				if(res.status === 200) {
					this.$message.success("案件が作成されました")
					this.$parent.isCreatCase = false;
					// this.$router.go(-1);
					this.$router.push({
						path: 'project-details',
						query: {
							pid: this.projectid
						}
					})
				} else {
					this.$message.error("案件が作成されませんでした")
				}
			})
		},
		cancel() {
			this.$parent.isCreatCase = false;
		}
	}
}

</script>
<style lang="scss" scoped>
	.main  {
		.formData {
			margin-top: 30px;
			h4 {
				color: #666;
				letter-spacing: 1px;
				margin-bottom: 20px;
			}
			::v-deep .avatar-uploader .el-upload {
                border: 1px solid #999;
                border-radius: 4px;
                cursor: pointer;
                position: relative;
                overflow: hidden;
                width: 60px;
                height: 60px;
                background: #f4f4f4;
            }
			.avatar {
                width: 178px;
                height: 178px;
                display: block;
            }
            .avatar-uploader-icon {
                font-size: 20px;
                color: #8c939d;
                line-height: 60px;
            }
		}
		.submit {
			display: flex;
            justify-content: center;
            height: 100px;
            align-items: center;
            padding-bottom: 70px;
            span:first-child {
                display: block;
                width: 200px;
                height: 50px;
                line-height: 50px;
                background: #F03747;
                border-radius: 8px;
                color: #fff;
                text-align: center;
                cursor: pointer;
            }
		}
	}
</style>